.invite_url_container {
	position: fixed;
	bottom: 0;
	z-index: 1;
	background: var(--left-panel-search-bg);
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.invite_url {
	width: 95%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	border: 1px solid var(--left-panel-search-color);
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	justify-content: space-between;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
	cursor: pointer;
	background: var(--left-panel-search-bg);
	color: var(--left-panel-search-color);
	margin: auto;
}
