.container {
	width: 95vw;
	height: 95vh;
	/* width: 1266px; */
	position: absolute;
	/* height: 668px; */
	left: 50%;
	top: 50%;
	background: rgba(194, 194, 194, 0.3);
	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(15px);
	border-radius: 20px;
	transform: translate(-50%, -50%);
	z-index: 1;
}
