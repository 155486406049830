.panel-settings {
	.settings-list {
		font-size: 15px;
	}
	.rc-slider-rail {
		background: var(--active-header-center-bg) !important;
	}
	.rc-slider-handle,
	.rc-slider-dot {
		border: solid 2px var(--active-header-center-bg) !important;
		background-color: var(--center-panel-background) !important;
		cursor: pointer !important;
	}
	.rc-slider-mark-text-active {
		color: var(---subtitle-color);
	}

	.close-button-border {
		border-color: rgba(255, 255, 255, 0.6);
	}
	.close-button-border:hover {
		border-color: #165996 !important;
	}
	.submit-btn {
		background-color: #308130;
	}

	.submit-btn:hover {
		background-color: #bebebe;
	}
	.link-btn {
		text-decoration: underline;
		font-size: 12px;
	}
}
