.dot {
	position: absolute;
	width: clamp(5vw, 15vw, 230px);
	height: clamp(5vw, 15vw, 230px);
	background: #f8f8f8;
	filter: blur(30px);
	border-radius: 50%;
}

.topRight {
	top: -10%;
	right: 20%;
}

.topLeft {
	left: 10%;
	top: 10%;
}
.bottomLeft {
	left: 10%;
	bottom: -10%;
}
.bottomRight {
	right: 10%;
	bottom: 20%;
}
