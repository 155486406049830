.toast {
	background-color: rgba(20, 30, 48, 0.65);
	border: 1px solid #424242;
	text-align: left;
	z-index: 600;
	width: 300px;
	list-style: none;
	height: auto;
	padding: 10px 20px;
	font-family: 'circular';
	font-weight: 300;
	border-radius: 2px;
	vertical-align: middle;
	margin-bottom: 20px;
}
