.all-members {
	.member-bar {
		border-radius: 4px;
	}
	.member-bar:hover {
		background: #30353a;
		color: var(--title-color);
		opacity: 1 !important;
	}

	.member-bar:hover #member-staus-bar svg,
	.member-bar:hover #member-staus-bar img {
		box-shadow: 0px 0px 0px 4px #30353a !important;
		background-color: #30353a !important;
	}
	.member-bar .member-staus-bar svg,
	.member-bar .member-staus-bar img {
		width: 9px !important;
		height: 9px !important;
		box-shadow: 0px 0px 0px 4px #262a2e !important;
		background-color: #262a2e !important;
		border-radius: 100px;
	}
}
