.team-header {
	background: var(--teamheader-bg);
	height: var(--top-bars-height);
}

.team-icon {
	width: 30px;
	height: 30px;
	background: var(--team-id-background);
	border-radius: 100px;
}

.sub-title {
	color: var(--subtitle-color);
}
#fillSvg {
	fill: var(--svg-background);
}
#fillSvg:hover {
	fill: var(--svg-background-hover);
}

.left-panel {
	background: var(--left-panel-background) !important;
	color: var(--theme-page-text) !important;
	overflow: hidden;
}
.left-panel-card {
	overflow-x: hidden;
}

.team-id {
	color: var(--team-id-color);
}
.modal-body-cont input {
	color: var(--subtitle-color);
}

.search-box-left-panel,
.search-left-panel {
	background: var(--left-panel-search-bg);
	border: none;
	color: var(--left-panel-search-color);
}

.search-box-left-panel input {
	color: var(--left-panel-search-color);
}

#search-icon-left-panel {
	fill: var(--left-panel-search-icon);
}
.left-panel {
	::-webkit-input-placeholder {
		/* Edge */
		color: var(--left-panel-search-icon) !important;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: var(--left-panel-search-icon) !important;
	}

	::placeholder {
		color: var(--left-panel-search-icon) !important;
	}
}

#chanel-plus-icon {
	fill: var(--add-button-color);
}
#chanel-plus-icon:hover {
	fill: var(--centerPanelSvg-hover);
	cursor: pointer;
}

.channel-plus-icon-box {
	margin-top: -21px;
}

.active-group {
	color: var(--theme-page-text);
	font-weight: 400;
}

.group {
	color: var(--inactive-group-color);
	font-weight: 400;
}

.sub-title2 {
	color: var(--subtitle-color2);
}
.sub-title {
	color: var(--subtitle-color);
}

#onlineDot {
	fill: var(--online-dot-bg);
}

#offlineDot {
	fill: var(--offline-dot-bg);
}

// .user-online-icon-box {
// 	margin-top: 2px;
// }

.user-item {
	position: relative;
	z-index: 1;
}
.user-item:hover,
.group:hover,
.active-group:hover {
	background: var(--user-name-hover-bg);
	border-radius: 3px !important;
	// padding: 0px 5px !important;
}

.user-item:active,
.group:active,
.active-group:active {
	box-shadow: 0px 0px 0px 1px var(--user-name-active-bg) !important;
	background: var(--user-name-active-bg) !important;
}
.user-item.active-group,
.active-group {
	box-shadow: 0px 0px 0px 1px var(--user-name-active-bg) !important;
	background: var(--user-name-active-bg) !important;
	border-radius: 3px !important;
}

.user-item:hover .buttons-overlay-users-left-panel {
	display: block;
}

.buttons-overlay-users-left-panel {
	display: none;
	position: absolute;
	right: 20px;
	top: 3px;
}

.user-hvr-icon-cont {
	pointer-events: all !important;
	width: 25px;
	height: 25px;
	// background: #e6e7ed;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	background-size: 48%;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}
.user-hvr-icon-cont-video {
	background-size: 56% !important;
}

.user-hvr-icon-cont:hover {
	box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.486) !important;
}
.user-hvr-icon-cont:active {
	box-shadow: 0px 0px 1px 5px rgba(255, 255, 255, 0.486) !important;
}

.truncate {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.truncate-username {
	width: calc(100% - 1rem - 21px - 0.48225rem - 9px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.selected-room-bg {
	position: absolute;
	// background: #2125292c;
	z-index: 0;
	height: 22px;
	left: 0px;
	margin-top: -32px;
}
.selected-channel {
	margin-top: -32px !important;
	background: #2125292c;
}
.unread-count {
	font-weight: 400;
	font-size: 10px;
	position: absolute;
	margin-top: -4px;
	margin-left: -12px;
	height: 14px;
	font-family: 'DMSans';
	background: #ffffff;
	color: black;
	vertical-align: middle;
	// padding-top: 2px;
	// padding-bottom: 2px;
	width: 14px !important;
	text-align: center;
	line-height: 13px;
	z-index: -1;
	border-radius: 1px;
}

.avatar-selector .sb-avatar__text {
	box-shadow: 0px 0px 0px 3px #14141c !important;
	opacity: 1 !important;
	z-index: 444;
}

.avatar-selector-no-bs .sb-avatar__text {
	box-shadow: none !important;
}

.dm-rooms-container {
	height: calc(100vh - var(--top-bars-height));
	overflow: auto;
	position: relative;
	overflow-x: hidden;
	margin-right: -6px;
	overflow-y: auto;
}

#status-change {
	background: var(--status-change);
	text-align: left;
	width: 270px;
	opacity: 1;
	padding: 5px 10px;
	font-family: circular;
	font-weight: 300;
	left: 20px !important;
	top: 77px !important;
	box-shadow: 0px 0px 0px 2px #a3a3a31c;
	border-radius: 5px;
	font-size: 13px;
	transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.1s;
}
.status-option:hover {
	background: #1b1b23;
	// box-shadow: 0px 0px 0px 1px #1b1b23;
	border-radius: 5px;
	cursor: pointer;
}
#status-input,
#status-input input {
	background: var(--left-panel-background) !important;
	transition: 0s !important;
}

.blink_div {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.usericons-leftpanel {
	background-color: #14141c;
	background-size: cover;
	background-position: center;
	width: 25px;
	height: 25px;
	border-radius: 100px;
}

.profile-drop-btn {
	transition: 0s;
}
