.select_input_container {
	background: rgba(47, 49, 53, 1);
	padding: 1rem;
	border-radius: 6px;
	filter: drop-shadow(0.15rem 0.15rem 0.4rem rgba(0, 0, 0, 0.5));
}

.options_container {
	margin-top: 1rem;
	max-height: 10rem;
	overflow: auto;
}
