.member-profile {
	margin-top: -25px;
	.snap {
		width: 100%;
		height: 130px;
		background-size: cover;
		background-position: center;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
	}
	.avatar-box {
		box-shadow: 0px 1px 0px 10px #1c1c20;
		border-radius: 100px;
		margin-top: -45px;
		margin-left: 27px;
	}
	.user-status-box {
		margin-left: -20px;
		margin-top: 5px;
	}
	.dm {
		border-color: rgb(10, 104, 32) !important;
		background: rgb(10, 104, 32) !important;
		font-weight: 300 !important;
		border-radius: 3px !important;
		vertical-align: middle !important;
	}
}
#member-profile .user-status-box svg,
#member-profile .user-status-box img {
	width: 15px !important;
	height: 15px !important;
	box-shadow: 0px 0px 0px 7px #1c1c20;
	background: #1c1c20;
	border-radius: 100px;
}
