.context-menu-box {
	background: #18191c;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
	border-radius: 4px;
	padding: 10px;
	width: 205px;
	padding-top: 11px;
}

.context-item {
	padding: 5px 5px 5px 10px;
	margin-bottom: 3px;
	color: rgba(255, 255, 255, 0.568);
}
.context-item:hover {
	background: #2e3144;
	border-radius: 2px;
	color: white !important;
}

.context-item:active {
	background: #47495f;
}
.divider-context {
	background: none;
	margin: 6px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.06);
}
.react-contextmenu-item--disabled {
	opacity: 0.34 !important;
	cursor: not-allowed;
	pointer-events: none;
}
.danger-items {
	color: #ed4245;
}

.danger-items:hover {
	background: #ed4245;
	border-radius: 2px;
	color: white !important;
}

.danger-items:active {
	background: #ed4245;
}
