.settings-btn {
	&:hover {
		color: rgba(255, 255, 255, 0.8);
		border-color: rgba(255, 255, 255, 0.8);
	}
	&:focus {
		color: rgba(255, 255, 255, 0.8);
		border-color: rgba(255, 255, 255, 0.8);
	}
}
