.single_option {
	display: flex;
	padding: 0.3rem 0.5rem;
	border-radius: 6px;
}
.single_option:hover {
	background: rgba(38, 40, 42, 1);
}

.single_option:not(:last-child) {
	// margin-bottom: 0.5rem;
}

.single_option--txt {
	display: flex;
	text-align: left;
	cursor: pointer;
	line-height: 1rem;
	font-size: 0.85rem;
	align-items: center;
}
