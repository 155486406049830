.selected {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.2rem;
	padding: 0.3rem;
	background-color: rgba(48, 50, 56, 0.7);
	border-radius: 6px;
	transition: all 0.2s;
	cursor: pointer;
	overflow: hidden;
}

.selected:hover::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.remove {
	transition: all 0.2s;
	transform: scale(0);
	position: absolute;
	top: -0px;
	right: 4px;
	visibility: hidden;
}

.selected:hover .remove {
	transform: scale(1);
	visibility: visible;
}

.selected {
	margin-right: 0.3rem;
}

.name {
	font-size: 0.7rem;
	white-space: nowrap;
	max-width: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.selected_container {
	max-width: 70%;
	display: flex;
	flex-wrap: wrap;
	gap: 0.3rem;
}
