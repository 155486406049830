.notification__item--info {
	background-color: #0b0b11 !important;
	border-left: 8px solid #dbdbdbc2 !important;
}

.notification__item--danger {
	background-color: #6a252b !important;
	border-left: 8px solid #ee8a93 !important;
}

.notification__item--success {
	background-color: #0a6820 !important;
	border-left: 8px solid #1f8337 !important;
}

.notification__timer {
	background-color: #ffffff29 !important;
}

.notification__close {
	background-color: #dcdef142 !important;
	margin-top: 5px !important;
	margin-right: 4px !important;
}
