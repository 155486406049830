.active-header-center {
	background: var(--active-header-center-bg);
	height: var(--top-bars-height);
	// position: fixed;
	z-index: 200;
	// width: inherit;
	width: '100%';
}

#centerPanelSvg {
	fill: var(--centerPanelSvg-bg);
}

#centerPanelSvg:hover {
	fill: var(--centerPanelSvg-hover);
	cursor: pointer;
}

.three-dot-icon-box {
	margin-top: -12px;
}

.settings-icon-box {
	margin-top: -13px;
}

.center-panel {
	::-webkit-input-placeholder {
		/* Edge */
		color: var(--left-panel-search-icon) !important;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: var(--left-panel-search-icon) !important;
	}

	::placeholder {
		color: var(--left-panel-search-icon) !important;
	}
}

.chat-box {
	// padding-top: var(--top-bars-height);
	overflow: auto;
	height: calc(100% - var(--top-bars-height) - 55px);
	position: fixed;
	width: inherit;
	overflow-x: hidden !important;
}
.members-list {
	background: #262a2e;
	// overflow-x: hidden !important;
	// height: calc(100% - var(--top-bars-height) - 55px);
}
.chat-block {
	font-weight: 400;
	width: 100%;
	border-radius: 4px;
	white-space: pre-wrap;
}

#border-top-chat-block {
	border-top: 1px solid var(--chat-block-border-color);
}

.quoted-block {
	border-left: 5px solid var(--quoted-border-bg);
	border-radius: 2px;
	background-color: var(--quoted-bg);
}

.center-panel-card {
	position: relative;
	width: inherit;
	overflow-x: hidden !important;
}

.input-center-panel {
	position: fixed;
	bottom: 0;
	width: inherit;
	background: none;
}
// .input-center-panel .search-box-left-panel {
// 	// box-shadow: 0px 0px 1px 1px rgba(202, 202, 203, 0.32);
// 	border-radius: 3px !important;
// }
.input-center-panel .search-left-panel:focus {
	// background: var(--central-panel-message-input) !important;
	// border-radius: 3px !important;
	box-shadow: none !important;
	border: none !important;
	font-weight: 100;
	// color: #000;
}

#input-container {
	// background: var(--central-panel-message-input) !important;
	box-shadow: none !important;
	outline: none !important;
	// border-radius: 3px !important;
	box-shadow: none !important;
}

.emoji-picker {
	position: absolute;
	z-index: 500;
	bottom: 50px;
	right: 0;
}

#emojiTrigger {
	fill: var(--emoji-trigger-active);
}

#emojiTrigger {
	fill: var(--left-panel-search-icon);
}

.emoji-mart-title-label {
	display: none;
}

.emoji-mart-dark {
	border-color: #555453 !important;
	background-color: #1c1c20 !important;
}

.emoji-mart-dark .emoji-mart-category-label span {
	background-color: #1c1c20 !important;
	font-size: 14px !important;
	font-weight: 300 !important;
}

.emoji-mart-dark .emoji-mart-search input {
	border-color: #1c1c20 !important;
	background-color: #212529 !important;
}

.chatbox-ice {
	vertical-align: middle;
	text-align: center;
	margin-top: 80px;
}
.chat-block:hover,
.chat-block.active-message,
.no-user-info.active-message .chat-block {
	box-shadow: -3px 1px 0px 3px #ffffff08 !important;
	// border-radius: 0.15px;
	background: #ffffff08 !important;
}

.no-user-info .chat-block {
	padding-right: 1px;
}

.chat-block,
.user-info-top {
	padding-left: 1px;
}
#active-reply .chat-block {
	box-shadow: -3px 0px 0px 3px #2f3643 !important;
	border-radius: 2px;
	background: #2f3643 !important;
}

#active-reply .user-info-top {
	box-shadow: 0px -2px 0px 6px #2f3643 !important;
	border-radius: 2px;
	background: #2f3643 !important;
}

.user-info-top:hover,
.user-info-top.active-message {
	box-shadow: 0px 0px 0px 6px #ffffff08 !important;
	border-radius: 2px;
	background: #ffffff08 !important;
}
.user-info-top {
	border-radius: 0.5px;
}

.chat-block:hover .action-block-chat,
.user-info-top:hover .action-block-chat {
	visibility: visible !important;
	// padding-top: 1px;
}
.action-block-chat {
	visibility: hidden;
	height: 0px;
	width: 0px;
	position: relative;
}
.copy-message-box {
	top: -13px;
	position: absolute;
	right: -55px;
	width: 200px;
}

#copy-message-icon {
	fill: var(--left-panel-search-icon);
}

#copy-message-icon:hover {
	fill: var(--subtitle-color);
	cursor: pointer;
}

#copy-message-icon:active,
#copy-message-icon:focus {
	fill: var(--theme-page-text);
	cursor: pointer;
}
.copied-message-active {
	color: var(--subtitle-color2);
	font-weight: 200;
	margin-bottom: 7px;
	vertical-align: middle;
	background: #1c1c20 !important;
	padding: 4px 9px;
	border-radius: 2px;
	line-height: initial;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	-ms-transition-property: opacity;
	transition-property: opacity;
	-webkit-transition-duration: 500ms;
	-moz-transition-duration: 500ms;
	-o-transition-duration: 500ms;
	-ms-transition-duration: 500ms;
	transition-duration: 500ms;
	opacity: 1;
	-ms-filter: none;
	filter: none;
}

.copied-message {
	opacity: 0;
	color: var(--subtitle-color);
	font-weight: 200;
	margin-bottom: 7px;
	vertical-align: middle;
	background: #1c1c20;
	padding: 4px 9px;
	border-radius: 2px;
	line-height: initial;
}
.emoji-trigger-box {
	margin-top: 5px;
	display: block;
}
.reply-tag {
	padding: 6px 14px;
	background: #2f3643;
	font-family: 'circular';
	font-weight: 100;
	border-radius: 4px 4px 0px 0px;
	font-size: 16px;
}
.close-reply:active,
.close-reply:focus {
	box-shadow: 0px 0px 1px 5px rgba(255, 255, 255, 0.486) !important;
}

.file-input svg path:active,
.file-input svg path:focus {
	fill: white;
	box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.486);
}
.file-input svg,
.file-input {
	background: none !important;
	border: none !important;
}

.file-input svg:active,
.file-input svg:focus {
	box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.486);
}
.file-input svg {
	border-radius: 100px;
}
.close-reply:hover {
	box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.486);
}
#file-input-overlay {
	background: rgba(2, 2, 2, 0.81);
	position: absolute;
	z-index: 1000000;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}
#file-preview {
	border-radius: 7px;
}
#upload-button {
	padding: 7px 26px;
	height: auto;
	vertical-align: middle;
	background: #41558b;
	border-radius: 3px;
	border-color: #41558b;
}
.cancel-btn:hover {
	text-decoration: underline;
}

.file-block {
	display: inline-block;
	background: #1c1c20;
	padding: 10px 14px 10px 13px;
	border-radius: 4px;
}

.file-link {
	max-width: 150px;
	display: table-cell;
	overflow: hidden;
	color: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.file-link:hover {
	text-decoration: underline;
}

.ant-spin-dot-item {
	background: white;
}
.action-buttons-chat {
	background: #1c1c20;
	padding: 4px 8px 1px 8px;
	border-radius: 3px;
}

.close-reply {
	background: white;
	border-radius: 100px;
	width: 16px;
	height: 16px;
	margin-top: 5px;
	margin-right: -4px;
}
