.ant-dropdown-placement-bottomLeft {
	width: 400px !important;
	min-width: 400px !important;
}

.custom-select-input {
	text-align: left;
	margin-top: 0.23257rem;
}

.custom-select-input .ant-select-selector {
	padding: 4px 11px;
	border-color: transparent !important;
	border-radius: 6px !important;
	border: none;
}
.custom-select-input .ant-select-selector .ant-select-selection-placeholder {
	text-align: left;
	left: 15px;
}
