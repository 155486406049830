::-webkit-scrollbar {
	background-color: none;
	width: 6px;
	height: 6px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
	background-color: #1f2225;
}
// ::-webkit-scrollbar-track:hover {
// 	background-color: #1f2225;
// }

/* scrollbar itself */
::-webkit-scrollbar-thumb {
	background-color: #191b1d;
	border-radius: 105px;
	// border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
	// background-color: #1b1f21;
	// border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
	display: none;
}

//for firefox
html,
div {
	scrollbar-width: thin;
}
