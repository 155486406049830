.peers-main-container {
	max-width: 95%;
	display: grid !important;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-auto-columns: auto;
	width: 100%;
	margin: 0 auto;
	list-style: none;
	justify-content: center;
}

.peer-main-container {
	width: 100% !important;
	max-width: 95% !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.peers-main-container {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	}
	.peer-main-container {
		width: 100px !important;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.peers-main-container {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.peers-main-container {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.peers-main-container {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.peers-main-container {
		grid-template-columns: repeat(auto-fit, minmax(350px, 0.7fr));
	}
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
	.peers-main-container {
		grid-template-columns: repeat(auto-fit, minmax(400px, 0.5fr));
	}
}
