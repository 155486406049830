.overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
	z-index: 9999;
}
