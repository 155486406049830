.login-box {
	// background: var(--left-panel-background);
	height: 100vh;
	width: 100vw;
}

.illustration-bg {
	padding-left: 10%;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 70% 70%;
	height: 100vh;
	width: 100vw;
}

.login-right {
	text-align: left;
	display: table-cell;
	vertical-align: middle;
	height: 100vh;
}

.login-input {
	padding: 15px 20px;
	background-color: var(--login-input-box) !important;
	border: none;
	color: white;
	width: 300px;
	border-radius: 8px;
	display: flex;
}
.login-input:focus {
	background-color: var(--login-input-box) !important;
	color: white;
	width: 300px;
	border-radius: 8px;
	display: flex;
}

#login-input::placeholder {
	color: var(--subtitle-color2) !important;
	opacity: 1 !important;
}

.login-btn {
	background: var(--login-button-bg);
	color: var(--modal-header);
	border: none;
	border-radius: 8px;
	padding: 12px 30px;
	height: auto;
	vertical-align: middle;
	font-weight: 500;
	font-size: 18px;
}

.forget-password,
.send-message {
	display: inline-block;
	color: var(--subtitle-color2);
	text-decoration: underline;
	font-weight: 100;
	font-size: 14px;
}

.max-width {
	max-width: 90% !important;
	& > .ant-form-item-control {
		max-width: 85%;
	}
}
.team_input {
	max-width: 100%;
	display: block;
	background-color: #e8f0fe;
	color: #3c4142;
	border-radius: 5px;
	& > .ant-form-item-control {
		max-width: 100%;
	}
}

.input_dark_color {
	&.ant-form-item-has-error {
		border: 1px solid #a61d24;
	}
	&::placeholder {
		color: rgba(0, 0, 0, 0.4);
	}
}
