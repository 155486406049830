.panel-header-right {
	background: var(--panel-header-right-bg);
	height: var(--top-bars-height);
	width: '100%';
}

.chat-head-box {
	cursor: pointer;
	background-size: cover;
	background-position: center;
	height: 190px;
	width: 100%;
	display: inline-block;
	position: relative;
	box-shadow: 0px 0px 0px 1px #21262912;
}

.chat-head-name {
	position: absolute;
	// bottom: 5px;
	bottom: 0px;
	// left: 7px;
	font-family: circular;
	// background: #212629ab;
	border-top-right-radius: 2px;
	// padding-left: 2px;
	width: 100%;
	color: var(--central-panel-message-input);
}

.grid-container {
	display: grid;
	overflow-x: hidden;
	width: 100%;
	overflow-y: auto;
	max-height: calc(100% - var(--top-bars-height));
	position: fixed;
	padding-bottom: 1px !important;
}

.grid-container--fill {
	grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
}

.chat-head-box:hover .hover-overlay,
.chat-head-box:hover .buttons-overlay {
	display: block !important;
}
.hover-overlay-block {
	display: block !important;
	height: 190px;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: #14171ac9 !important;
}
.chat-head-box:hover .chat-head-name {
	background: none !important;
}
.hover-overlay {
	display: none;
	height: 190px;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: #212629c9 !important;
}

.buttons-overlay {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
}
.icon-cont {
	cursor: pointer;
	pointer-events: all !important;
	width: 40px;
	height: 40px;
	// background: #e6e7ed;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	background-size: 50%;
	background-position: center;
	background-repeat: no-repeat;
}
// .icon-cont-video {
// 	padding: 1px 7px 3px 7px !important;
// }
.icon-cont-video {
	background-size: 60% !important;
}

.icon-cont:hover {
	box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.486) !important;
}
.icon-cont:active {
	box-shadow: 0px 0px 1px 5px rgba(255, 255, 255, 0.486) !important;
}

.right-panel {
	overflow-x: hidden;
}

.timer-container {
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 60px;
	height: 60px;
}
