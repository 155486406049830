@import '~antd/dist/antd.dark.css';

@font-face {
	font-family: 'product';
	font-weight: 400;
	src: url('../fonts/ProductSans-Regular.ttf');
}
@font-face {
	font-family: 'product';
	font-weight: 700;
	src: url('../fonts/Product-Bold.ttf');
}

@font-face {
	font-family: 'product';
	font-weight: 100;
	src: url('../fonts/ProductSans-Thin.ttf');
}

@font-face {
	font-family: 'product';
	font-weight: 300;
	src: url('../fonts/ProductSans-Light.ttf');
}

@font-face {
	font-family: 'product';
	font-weight: 500;
	src: url('../fonts/ProductSans-Medium.ttf');
}

@font-face {
	font-family: 'circular';
	font-weight: 700;
	src: url('../fonts/circular-bold.woff');
}

@font-face {
	font-family: 'circular';
	font-weight: 500;
	src: url('../fonts/circular-medium.woff');
}
@font-face {
	font-family: 'circular';
	font-weight: 400;
	src: url('../fonts/CircularStd-Book.otf');
}
@font-face {
	font-family: 'circular';
	font-weight: 100;
	src: url('../fonts/CircularStd-Light.ttf');
}

@font-face {
	font-family: 'DMSans';
	font-weight: 500;
	src: url('../fonts/DMSans-Medium.ttf');
}

@font-face {
	font-family: 'DMSans';
	font-weight: 800;
	src: url('../fonts/DMSans-Bold.ttf');
}

@font-face {
	font-family: 'DMSans';
	font-weight: 400;
	font-style: italic;
	src: url('../fonts/DMSans-Italic.ttf');
}

.light {
	--theme-color: #a80000;
	--theme-page-background: #fff;
	--theme-page-text: #111;
}

.dark {
	--theme-color: #0000a8;
	--login-button-bg: #fff;
	--theme-page-background: #111;
	--theme-page-text: #fff;
	--left-panel-background: #14141c;
	--login-input-box: #26262e;
	--center-panel-background: #212529;
	--right-panel-background: #31333e52;
	--team-id-background: #607d8b;
	--svg-background: rgba(245, 245, 245, 0.8);
	--svg-stroke: rgba(245, 245, 245, 0.8);
	--title-color: white;
	--subtitle-color: rgba(255, 255, 255, 0.6);
	--subtitle-color2: rgba(255, 255, 255, 0.4);
	--chat-block-border-color: rgba(255, 255, 255, 0.02);
	--teamheader-bg: #1c1c26;
	--team-id-color: #1c1c26;
	--left-panel-search-bg: #303238;
	--left-panel-search-icon: #b2b5ba;
	--left-panel-search-color: #b2b5ba;
	--add-button-color: rgba(255, 255, 255, 0.4);
	--inactive-group-color: rgba(255, 255, 255, 0.5);
	--online-dot-bg: rgb(1 145 131);
	--offline-dot-bg: #69696f52;
	--active-header-center-bg: #2f3135;
	--top-bars-height: 75px;
	--centerPanelSvg-bg: rgba(255, 255, 255, 0.4);
	--quoted-border-bg: #509cc1;
	--quoted-bg: #c0eaff08;
	--panel-header-right-bg: #1d1f2b;
	--user-name-hover-bg: #1c1d26;
	--user-name-active-bg: #272835;
	--central-panel-message-input: rgb(218, 218, 218);
	--centerPanelSvg-hover: rgba(255, 255, 255, 0.95);
	--svg-background-hover: rgba(255, 255, 255, 0.95);
	--modal-background: rgb(51, 59, 64);
	--modal-header: #2f3135;
	--modal-box-shadow: rgb(245 245 245 / 10%);
	--emoji-trigger-active: #383a4b;
	--danger-color: #ed4245;
	--status-change: #2f3135;
}

#strokeSvg {
	stroke: var(--svg-stroke);
}

.App {
	background: var(--theme-page-background);
	color: var(--theme-page-text);
	overflow-x: hidden !important;
}
body,
html {
	overflow-x: hidden !important;
}

.circular {
	font-family: circular;
}

.dm-sans {
	font-family: DMSans;
}
.product {
	font-family: product;
}
.notification__content {
	font-family: circular !important;
	padding: 17px 17px !important;
}

.notification__title {
	margin-bottom: 2px !important;
	font-weight: 500 !important;
}
.notification__message {
	font-weight: lighter !important;
	font-family: circular !important;
	font-size: 12px !important;
}
.right-panel,
.center-panel,
.left-panel {
	overflow-y: auto;
	height: 100%;
	overflow-x: hidden;
}

.center-panel {
	background: var(--center-panel-background);
}
.right-panel {
	background: var(--right-panel-background);
}
.cursor {
	cursor: pointer !important;
}
.react-grid-item > .react-resizable-handle::after {
	width: 10px !important;
	height: 10px !important;
	border-right: 2px solid rgb(96, 125, 138) !important;
	border-bottom: 2px solid rgb(96, 125, 138) !important;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.sb-avatar__text {
	opacity: 0.8 !important;
}

.section-header {
	margin-bottom: -3px;
}
.modal-main {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	z-index: 600;
	width: 650px;
	padding: 75px;
}
.modal-body-cont {
	border-radius: 5px;
	box-shadow: rgba(245, 245, 245, 0.1) 0px 0px 0px 4px;
	background: var(--modal-background);
}
.modal-header {
	text-align: left;
	border-radius: 5px 5px 0px 0px;
	background: var(--modal-header);
}
.cross-icon {
	cursor: pointer;
	pointer-events: all !important;
	width: 25px;
	height: 25px;
	// background: #e6e7ed;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	// background-size: 40%;
	background-size: 11px 11px;
	background-position: center;
	background-repeat: no-repeat;
}
.cross-icon:hover {
	box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.486) !important;
}
.cross-icon:active {
	box-shadow: 0px 0px 1px 5px rgba(255, 255, 255, 0.486) !important;
}
.cross-icon-small {
	width: 16px !important;
	height: 16px !important;
	vertical-align: middle;
}
.userlist-dropdown {
	background: #35393e !important;
	color: rgba(255, 255, 255, 0.6) !important;
	font-family: circular;
	font-weight: 100;
	border-radius: 5px;
	width: 197px;
	max-height: 170px;
	overflow: auto;
}
.user-item-drop:hover {
	background: #393e44;
	box-shadow: 0px 0px 0px 2px #393e44 !important;
	cursor: pointer;
	border-radius: 2px;
}
.user-item-drop:hover #user-item-drop-border {
	box-shadow: none !important;
}

#user-item-drop-border {
	box-shadow: 0px -1px 0px 0px #393e44;
}

.danger-color {
	color: var(--danger-color) !important;
}

// .search-box-left-panel .ant-select-selector {
// 	background: var(--left-panel-search-bg) !important;
// 	border: none !important;
// 	color: var(--left-panel-search-color) !important;
// }

// .ant-select-dropdown {
// 	background-color: #30323b !important;
// 	font-family: circular !important;
// }

.selected-user-drop {
	background: #333b40;
	border-radius: 4px;
}

.create-btn {
	background: #384550 !important;
	border-color: #9ba6af !important;
	font-weight: 100 !important;
}

.totoro {
	-webkit-animation: mover 2s infinite alternate !important;
	animation: mover 2s infinite alternate !important;
}
.m-t-2px {
	margin-top: 2px;
}
@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-30px);
	}
}
@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-30px);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: inherit !important;
}
