.large-notif {
	position: absolute;
	top: 13px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 1000;
	animation: blinker2 0.3s linear;
	transition: all 0.3s ease-in-out 0s, visibility 0s linear 3s;
	.large-notif-cont {
		background: #353b41;
		border-radius: 3px;
		box-shadow: 0px 0px 0px 3px #ffffff28;
	}

	@keyframes blinker2 {
		0% {
			opacity: 0.4;
		}
		50% {
			opacity: 0.7;
		}
		100% {
			opacity: 1;
		}
	}

	.call-info {
		font-size: 14px;
	}
	.user-info {
		width: 70vw;
		max-width: 1100px;
		padding: 23px 30px;
	}
	.user-img {
		box-shadow: 0px 0px 0px 3px #e1e1e1;
		width: 50px;
		height: 50px;
		border-radius: 100px;
		background-size: cover !important;
	}
	.knock-img {
		margin-left: -25px;
		margin-top: 20px;
		box-shadow: 0px 0px 0px 4px #353b41;
		border-radius: 100px;
		background: #353b41;
	}
	.call-btn {
		border-color: rgba(255, 255, 255, 0.6);
	}
	.call-btn:hover {
		border-color: rgba(255, 255, 255, 0.9);
		color: var(---subtitle-color);
	}
}
