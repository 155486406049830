.input {
	width: 100%;
	filter: drop-shadow(0.15rem 0.15rem 0.2rem rgba(0, 0, 0, 0.2));
	background: rgba(114, 118, 124, 1);
	border-radius: 6px;
	padding: 0.5rem 0.7rem;
	display: flex;
	align-items: flex-start;
	border: 2px solid transparent;
	transition: all 0.2s;
}

.input--hovering {
	border: 2px solid rgb(25, 25, 29);
	filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.2));
	cursor: text;
}

.select_input_main {
	border: none;
	background: transparent;
	width: 100%;
	margin: auto;
	min-width: 30%;
	flex: 1;
}
.select_input_main:focus {
	outline: none;
}

.select_input_main::placeholder {
	color: rgb(78, 79, 83);
}

.remove_all {
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
